<template>
    <div>
        <div class="progress">
           <van-tabs type="card" color="#474747" swipeable>
            <van-tab title="等待上传" class="tabs">
                <div class="video_box_outer">
                    <div class="video_box">
                        <div class="img_box">
                            <van-image width="135" height="82" src="https://img01.yzcdn.cn/vant/cat.jpeg"/>
                        </div>
                        <div class="detail_box">
                            <div class="detail">
                                <span class="detail_title">【病例分享】肺癌科研1号1号1号</span>
                                <span class="detail_date">2021.08.02</span>
                                <span class="detail_status"><van-icon name="share" />待上传</span>
                            </div>
                        </div>
                        <div class="operate_box">
                            <!-- <van-button></van-button> -->
                            <img src="/static/uploadAfter.png"/>
                        </div>
                    </div>
                <!-- end -->
                </div>
            </van-tab>
            <van-tab title="正在审核" class="tabs">
                <div class="video_box_outer">
                    <div class="video_box">
                        <div class="img_box">
                            <van-image width="135" height="82" src="https://img01.yzcdn.cn/vant/cat.jpeg"/>
                        </div>
                        <div class="detail_box">
                            <div class="detail">
                                <span class="detail_title">【病例分享】肺癌科研1号1号1号</span>
                                <span class="detail_date">2021.08.02</span>
                                <span class="detail_status detail_audit"><van-icon name="underway" />审核中</span>
                            </div>
                        </div>
                        <div class="operate_box">
                            <!-- <van-button></van-button> -->
                            <img src="/static/loadingAfter.png"/>
                        </div>
                    </div>
                <!-- end -->
                </div>
            </van-tab>
            <van-tab title="审核失败" class="tabs">
                <div class="video_box_outer">
                    <div class="video_box">
                        <div class="img_box">
                            <van-image width="135" height="82" src="https://img01.yzcdn.cn/vant/cat.jpeg"/>
                        </div>
                        <div class="detail_box">
                            <div class="detail">
                                <span class="detail_title">【病例分享】肺癌科研1号1号1号</span>
                                <span class="detail_date">2021.08.02</span>
                                <span class="detail_status detail_fail"><van-icon name="clear" />审核失败</span>
                            </div>
                        </div>
                        <div class="operate_box">
                            <!-- <van-button></van-button> -->
                            <img src="/static/failAfter.png"/>
                        </div>
                    </div>
                <!-- end -->
                </div>
            </van-tab>
            <van-tab title="审核成功" class="tabs">
                  <div class="video_box_outer">
                    <div class="video_box">
                        <div class="img_box">
                            <van-image width="135" height="82" src="https://img01.yzcdn.cn/vant/cat.jpeg"/>
                        </div>
                        <div class="detail_box">
                            <div class="detail">
                                <span class="detail_title">【病例分享】肺癌科研1号1号1号</span>
                                <span class="detail_date">2021.08.02</span>
                                <span class="detail_status detail_success"><van-icon name="checked" />审核成功</span>
                            </div>
                        </div>
                        <div class="operate_box">
                            <!-- <van-button></van-button> -->
                            <img src="/static/successAfter.png"/>
                        </div>
                    </div>
                </div>
            </van-tab>
           </van-tabs>
        </div>
        <Tabbar :name="'video'"></Tabbar>
    </div>
      
</template>
<script>
import Tabbar from '@/components/tabbar.vue'
export default {
    components: {
        Tabbar
    },
    data(){
        return {
            
        }
    },
    methods:{

    }
}
</script>
<style lang="scss">
    .progress {
        height: 100%;
        width: 100%;
        padding-top: 10px;
        box-sizing: border-box;
        padding: 10px 11px 10px 11px;
        padding-bottom: constant(safe-area-inset-bottom)+70;
        padding-bottom: env(safe-area-inset-bottom);
        margin-bottom: 170px;

        .van-tabs__wrap {
            margin: 0;

            .van-tabs__nav--card {
                border: none;

                .van-tab--active {
                    background: transparent !important;
                    color: #849EFB;
                    border: 1px solid #849EFB !important;
                    border-radius: 10px;
                }

                .van-tab {
                    margin: 0 5px;
                    border-radius: 10px;
                    border: 1px solid #474747;
                }
            }
        }


        .title {
            font-size: 14px;
            color: #232323;
            text-align: left;
            margin-bottom: 20px;
        }

        .statusList {
            width: 100%;
            height: 28px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
        }

        .tabs {
            padding-top: 15px;
            box-sizing: border-box;
        }

        .video_box_outer{
            width: 100%;
            .video_box{
                width: 100%;
                display: flex;
                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
                justify-content: space-between;
                .img_box{
                    display: flex;
                    padding: 9px;
                }
                .detail_box{
                    display: flex;
                    // width: %;
                    justify-content: space-between;
                    .detail{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: space-evenly;
                        .detail_title{
                            font-size: 14px;
                            color: #101010;
                            text-align: left;
                        }
                        .detail_date{
                            font-size: 12px;
                            color: #101010;
                        }
                        .detail_status{
                            font-size: 13px;
                            // color: #15DB4E;
                            display: flex;
                            align-items: center;
                        }
                        .detail_audit {
                            color: #2788FF;
                        }
                        .detail_fail {
                            color: #E74C3C ;
                        }
                        .detail_success {
                            color: #15DB4E ;
                        }
                    }
                    .detail_icon{
                        margin-right: 5px;
                    }
                }
                .operate_box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 44px;
                    img {
                        width: 18px;
                        margin-bottom: 5px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
</style>